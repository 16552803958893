<template>
  <Header></Header>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>修改密码</span>
      </div>
    </template>
    <el-form ref="mFormRef" :model="mForm" :rules="mRules" label-width="100px">
      <el-form-item label="用户名">
        <el-input
          v-model="mForm.userName"
          placeholder="请输入用户名"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="原密码" prop="oldPassword">
        <el-input
          v-model="mForm.oldPassword"
          :type="showPassword ? 'text' : 'password'"
          placeholder="原密码"
        />
        <div class="eye-p">
          <img
            v-show="showPassword"
            src="@/assets/image/eye.png"
            @click="showPassword = false"
          />
          <img
            v-show="!showPassword"
            src="@/assets/image/closeEye.png"
            @click="showPassword = true"
          />
        </div>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input
          v-model="mForm.newPassword"
          :type="showPassword2 ? 'text' : 'password'"
          placeholder="8-32位，含大小写字母、数字或特殊符号的其中两项"
        />
        <div class="eye-p">
          <img
            v-show="showPassword2"
            src="@/assets/image/eye.png"
            @click="showPassword2 = false"
          />
          <img
            v-show="!showPassword2"
            src="@/assets/image/closeEye.png"
            @click="showPassword2 = true"
          />
        </div>
      </el-form-item>
      <el-form-item label="确认密码" prop="rePassword">
        <el-input
          v-model="mForm.rePassword"
          :type="showPassword3 ? 'text' : 'password'"
          placeholder="需和上次输入一致"
        />
        <div class="eye-p">
          <img
            v-show="showPassword3"
            src="@/assets/image/eye.png"
            @click="showPassword3 = false"
          />
          <img
            v-show="!showPassword3"
            src="@/assets/image/closeEye.png"
            @click="showPassword3 = true"
          />
        </div>
      </el-form-item>
      <el-form-item label="图形验证码" prop="imageCode">
        <el-row :gutter="20">
          <el-col :md="18" :lg="18" :sm="15" :xs="12">
            <el-input v-model="mForm.imageCode" placeholder="图形验证码" />
          </el-col>
          <el-col :span="6" :xs="10">
            <img
              class="code-img"
              alt="图形验证码"
              title="点击刷新"
              @click="refreshCode()"
              :src="codeImg"
            />
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item class="mobileAgreement">
        <el-checkbox
          v-model="customCheck"
          type="checkbox"
          class="custom-control-input"
          id="customCheck"
        />
        <label class="custom-control-label" for="customCheck"
          >我已阅读并同意<router-link target="_blank" to="/agreement"
            >《外研教学评用户协议》</router-link
          >
          <router-link target="_blank" to="/privacy"
            >《外研教学评隐私政策》</router-link
          ></label
        >
      </el-form-item>
      <el-form-item>
        <el-button class="largeBtn" type="primary" @click="modifyPassword()"
          >确认修改</el-button
        >
      </el-form-item>
    </el-form>
    <div class="clearFloat">
      <div class="floatR">
        <router-link to="/login">登录系统</router-link>
      </div>
    </div>
  </el-card>
</template>

<script>
import Header from "@/components/Header.vue";
import { _createUUID } from "@/utils/utils.js";
import {
  validatePassword,
  validateNull,
  validatePassNull,
} from "@/utils/validate.js";
import { toRefs, reactive, getCurrentInstance, onMounted } from "vue";
import { toModifyPassword } from "@/api/api.js";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
export default {
  components: { Header },
  setup() {
    const { proxy } = getCurrentInstance(); // 使用proxy代替ctx，因为ctx只在开发环境有效
    const router = useRouter();
    const state = reactive({
      mForm: {
        userName: router.currentRoute.value.query.userName,
        oldPassword: "",
        newPassword: "",
        rePassword: "",
        imageCode: "",
      },
      mFormRef: null,
      customCheck: false,
      uuId: "",
      codeImg: "",
      showPassword: false,
      showPassword2: false,
      showPassword3: false,
    });
    onMounted(() => {
      refreshCode();
    });
    //获取图片验证码
    const refreshCode = () => {
      // 生成32位UUID
      state.uuId = _createUUID();
      //设置图片验证码
      state.codeImg =
        process.env.VUE_APP_BASE_PATH + "/auth/code?clientId=" + state.uuId;
    };
    const validateRePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else {
        if (value !== state.mForm.newPassword) {
          callback(new Error("两次密码输入不一致！"));
        }
        callback();
      }
    };
    const mRules = {
      oldPassword: [{ validator: validatePassNull, trigger: "blur" }],
      imageCode: [{ validator: validateNull, trigger: "blur" }],
      newPassword: [{ validator: validatePassword, trigger: "blur" }],
      rePassword: [{ validator: validateRePassword, trigger: "blur" }],
    };
    const modifyPassword = () => {
      state.mFormRef.validate((valide) => {
        if (valide) {
          if (state.customCheck) {
            toModifyPassword({
              userName: proxy.$encryption(state.mForm.userName),
              oldPassword: proxy.$encryption(state.mForm.oldPassword),
              newPassword: proxy.$encryption(state.mForm.newPassword),
              clientId: state.uuId,
              imageCode: state.mForm.imageCode,
            }).then((response) => {
              if (response["status"] == 0) {
                ElMessage({
                  message: "修改密码成功，即将跳转登录页......",
                  type: "success",
                });
                setTimeout(() => router.push("/login"), 3000);
              } else {
                refreshCode();
                ElMessage({
                  message: response["msg"],
                  type: "warning",
                });
              }
            });
          } else {
            ElMessage({
              message: "请阅读并选中协议～",
              type: "warning",
            });
          }
        }
      });
    };
    return {
      ...toRefs(state),
      modifyPassword,
      refreshCode,
      mRules,
    };
  },
};
</script>

<style lang="scss" scoped></style>
